import React from "react"

import "../components/tail-layout.css"

const HomePage = () => (
  <div>
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
            athlon.chat
          </h2>
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Commit to challenges
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            While we are working on web version, feel free to try iOS and
            Android apps
          </p>
        </div>
      </div>
    </section>
  </div>
)

export default HomePage
